.item {
  padding: 1.4rem;
  border: 2px dashed #4c4b4b;
  position: relative;

  justify-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item:hover {
  box-shadow: 0 0 1rem 1rem rgba(253, 242, 233, 0.6);
}

.item--articleNumber {
  position: absolute;
  bottom: -1.2rem;
  left: 0.5rem;

  font-size: 1.4rem;
  letter-spacing: 1px;

  padding: 0.5rem 1rem;
  letter-spacing: 2px;

  background-color: #fffbfa;
}

.item--title {
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: 600;
}

.item--title a:link,
.item--title a:active,
.item--title a:visited {
  text-decoration: none;
  color: #333;
}

.item--price {
  font-size: 1.6rem;
}

.item--price span {
  font-weight: 500;
}

.item--discount {
  position: absolute;

  top: -1.4rem;
  right: 3.8rem;
  /* right: 5px; */
  padding: 0.5rem 1rem;

  background-color: #8ce99a;

  font-size: 1.4rem;

  font-weight: 600;
}

.item--discount.expensive {
  background-color: #ffa8a8;
}

.item--btn-remove {
  position: absolute;
  right: 0.3rem;
  top: -1.8rem;

  font-size: 2.4rem;
  color: red;
  background-color: #fffbfa;

  transition: all 0.3s;

  cursor: pointer;

  border: none;
}

.item--btn-remove:hover {
  transform: scale(1.1);
}
