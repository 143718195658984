/****************************************
*** Section List
****************************************/
.section-list {
  padding: 4.8rem 0 9.6rem 0;
}

.section-list ion-icon {
  font-size: 2.4rem;
  margin-top: 0.5rem;
}

.heading {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.remove-margin {
  margin: 0;
}

.section-list--no-entries {
  font-size: 1.6rem;
  grid-column: 1 / -1;
}
