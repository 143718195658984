/****************************************
*** Section footer
****************************************/
.footer {
  border-top: 2px solid rgba(76, 75, 75, 0.1);
  padding-top: 1.6rem;
  padding: 1.6rem 0 3.2rem 0;
}

.footer a {
  text-decoration: none;
}

.footer--text {
  font-size: 1.2rem;
  line-height: 2;
  color: #4c4b4b;
}

.footer--text span {
  font-weight: 500;
  font-size: 1.4rem;
}

.footer--text--btn {
  cursor: pointer;
}

.footer--text--btn:hover {
  color: black;
}
