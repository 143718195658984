/****************************************
*** Section Input
****************************************/
.section-input {
  padding: 4.8rem 0 9.6rem 0;
}

.section-input form {
  display: grid;
  grid-template-columns: 60rem 40rem;
  justify-content: center;
  justify-items: center;
}

.section-input label {
  grid-column: 1 / -1;
  color: #333;
  letter-spacing: 2px;
}

.section-input form input {
  background-color: inherit;
  padding: 1.2rem 1.2rem 0 1.2rem;
  width: 100%;

  font-family: inherit;
  font-size: 2.4rem;
  letter-spacing: 2px;

  border-color: #4c4b4b;
  color: #333;

  border: none;
  border-bottom: 2px dashed #4c4b4b;

  grid-column: 1;
  align-self: flex-end;
}

.section-input form button {
  width: 80%;
  justify-self: center;
  color: #4c4b4b;
}

.section-input *:focus {
  outline: none;
}

.section-input .error {
  border-color: red;
  color: red;
}

.btn-primary {
  display: inline-block;
  background-color: inherit;
  padding: 1.6rem 3.2rem;

  border: 2px solid #4c4b4b;
  cursor: pointer;

  font-family: inherit;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 2rem;
  text-decoration: none;

  transition: all 0.3s;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #fdf2e9;
  box-shadow: inset 0 0 0 3px #fff;
  color: #333;
}

.inputDesc {
  font-size: 1.6rem;
  margin-top: 4.8rem;
  text-align: center;
  font-weight: 400;
}

.inputDesc.error {
  font-weight: 500;
  color: red;
}

/****************************************/
/* BELOW 1050px (Smaller PC)*/
/****************************************/

@media (max-width: 65em) {
  .section-input form {
    grid-template-columns: 1fr;
    row-gap: 2.4rem;
  }
}
