@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto Slab", sans-serif;
  line-height: 1;
  font-weight: 400;

  background-color: #fffbfa;
  color: #333;
}

@import "./components/UI/general.css";
