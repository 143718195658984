/****************************************/
/* GENERAL COMPONENTS */
/****************************************/

/**
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
**/

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  gap: 9.6rem;
  column-gap: 6.4rem;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--center-v {
  align-items: center;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--gap-sm {
  gap: 4.8rem;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  color: #333;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  letter-spacing: 2px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  letter-spacing: 2px;
}

.margin-top-sm {
  margin-top: 4.8rem;
}

.header {
  text-align: center;
  margin-top: 6.4rem;
}

/* Disable popup text from ion-icons */
ion-icon {
  pointer-events: none;
}

.btn--icon {
  border: none;
  background-color: inherit;
  cursor: pointer;

  transition: all 0.3s;
}

.btn--icon:hover {
  transform: scale(1.1);
  color: red;
}

/****************************************/
/* BELOW 1050px (Smaller PC)*/
/****************************************/
@media (max-width: 65em) {
  .grid--3-cols {
    grid-template-columns: 1fr 1fr;
    row-gap: 3.6rem;
  }
}

/****************************************/
/* BELOW 700px (Smaller Tablets)*/
/* 700px/16px =~44*/
/****************************************/
@media (max-width: 44em) {
  html {
    /*8px / 16px = 0.5*/
    font-size: 50% !important;
  }

  .grid--3-cols {
    grid-template-columns: 1fr;
    row-gap: 3.6rem;
  }

  .container {
    padding: 0 2.4rem;
  }
}
